import css3 from "../ImageComponent/css3.png";
import html from "../ImageComponent/html5.png";
import bootstrap from "../ImageComponent/bootstrap.png";
import vuejs from "../ImageComponent/logo.png";
import react from "../ImageComponent/react.png";
import js from "../ImageComponent/js.png";
import ejs from "../ImageComponent/ejs.png";
import node from "../ImageComponent/nodejs.png";
import mongodb from "../ImageComponent/mongodb.png";
import heroku from "../ImageComponent/heroku.jpeg";
import netlify from "../ImageComponent/netlify.png";
import postman from "../ImageComponent/postman.png";
import git from "../ImageComponent/git.png";
import github from "../ImageComponent/github.png";
import material from "../ImageComponent/material.png";
import jquery from "../ImageComponent/jquery.png";
import express from "../ImageComponent/express.png";
import python from "../ImageComponent/python.png";
import django from "../ImageComponent/django.jpg";
import cypress from "../ImageComponent/cypress.png";
import webdriver from "../ImageComponent/webdriver.svg";


export const skillData = [
    {
        title: "FrontEnd",
        image: [
            {pic:html, name:"HTML5"},{pic:css3, name:"CSS3"},{pic:bootstrap, name:"BOOTSTRAP5"},{pic:js, name:"JAVACRIPT"},{pic:jquery, name:"JQUERY"},{pic:react, name:"REACT-JS"},{pic:vuejs, name:"VUE-JS"},{pic:material, name:"MATERIAL-UI"},
            
            
        ]
    },
    {
        title: "BackEnd",
        image: [
            {pic:python, name:"PYTHON"},{pic:django, name:"DJANGO"},{pic:ejs, name:"EJS"},{pic:node, name:"NODE"},{pic:express, name:"EXPRESS"},{pic:mongodb, name:"MONGODB"},{pic:heroku, name:"HEROKU"},{pic:netlify, name:"NETLIFY"},
            {pic:postman, name:"POSTMAN"},
            
        ]
    },
    {
        title: "Development",
        image: [
            {pic:git, name:"GIT"},{pic:github, name:"GITHUB"},
        ]
    },
    // {
    //     title: "Automation Testing Tools",
    //     image: [
    //         {pic:cypress, name:"CYPRESS.IO"},
    //         {pic:webdriver, name:"WEBDRIVERIO"},
    //     ]
    // },
    
]

