import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReactTypingEffect from 'react-typing-effect';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function ScreenFlash() {
  const [progress, setProgress] = React.useState(10);

  const time = new Date().getHours();



  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);


          
    if(time>=0 && time<=12){
        return  (<div className="welcome">
                 <ReactTypingEffect 
                    className="head"
                    speed={200}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={100}
                    cursor="..."
                    text={"Good Morning"}
                />
                  <br/>
                 <br/>
                <CircularProgressWithLabel value={progress} />;
                <br/>
                 <br/>
                <ReactTypingEffect
                    className="head"
                    speed={100}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={100}
                    cursor="..."
                    text={"Welcome To Sanusi Hamed Portfolio"}
                />
        </div>) 
    }else if(time>12 && time<=16){
        return  (<div className="welcome">
                 <ReactTypingEffect 
                    className="head"
                    speed={200}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={100}
                    cursor="..."
                    text={"Good afternoon"}
                />
                <br/>
                 <br/>
                <CircularProgressWithLabel value={progress} />;
                <br/>
                 <br/>
                 <ReactTypingEffect
                    className="head"
                    speed={100}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={100}
                    cursor="..."
                    text={"Welcome To Sanusi Hamed Portfolio"}
                />
                
            </div>) 
    }else if(time > 16){
        return  (<div className="welcome" >
                <ReactTypingEffect
                   className="head"
                    speed={200}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={100}
                    cursor="..."
                    text={"Good evening"}
                />
                 <br/>
                 <br/>
            <CircularProgressWithLabel value={progress} />;
            <br/>
            <br/>
            <ReactTypingEffect
                   className="head"
                    speed={100}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={100}
                    cursor="..."
                    text={"Welcome To Sanusi Hamed Portfolio"}
                />
        </div>) 
    }

          
    
}
