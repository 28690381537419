export const experienceData = [
    {
        company: "Georgia southern University",
        role: "Graduate Teaching Assistant",
        duties : [
            "Contribute to the planning and delivery of lectures for Calculus 1, resulting in an increase in student understanding and performance.", 
            "Collaborate with faculty in grading and preparing materials for upcoming classes, lead weekly discussion sections for the students, and supervise students between classes, during tests, and exams.", 
        ],
        date: "Aug 2021 - Present",
        location: "Georgia"
    
    },
    {
        company: "Sona Agro Allied Food Limited",
        role: "Frontend Developer",
        duties : [
            "Developed modern web applications, by converting mockups into usable web presence with React.", 
            "Built modular and reusable components, also Fixed bugs and website issues that arise. ", 
            "Collaborated with web designers and back-end developers to improve usability.", 
            "Designed an interactive website with a great user experience, resulting in an increase in online buyers."
        ],
        date: "Feb 2020 - July 2021",
        location: "Lagos"
    
    },
    {
        company: "Scelloo",
        role: "Web Developer Intern",
        duties : [
            "Implemented part of the CRM modules for the frontend application using Vue.JS. ", 
            "Maintained code integrity, organization, quality and ensured responsiveness of the web applications.", 
            "Collaborated with the rest of the engineering team to design and launched new features"
        ],
        date: "Nov 2019 – Jan 2020",
        location: "Lagos"
    },

    {
        company: "Sona Agro Allied Food Limited",
        role: "Production Supervisor",
        duties: [
            "Supervised a team of 20 production workers, ensuring the efficient and effective operation of the production line.", 
            "Implemented a new scheduling system that increased production efficiency.", 
            "Collaborated with the quality control team to implement new procedures, resulting in an increase in product quality.",
            "Created and maintained production reports and tracked key performance indicators such as output, efficiency, and downtime."
        ],
        date: "Apr 2019 - Oct 2019",
        location: "Lagos"
    
    },
    
    {
        company: "Nigerian Bottling Company",
        role: "Assistant production Analyst(Intern)",
        duties : [
            "Analyzed and cleaned large datasets using Python, resulting in an increase in data accuracy.", 
            " Assisted with developing a predictive model using Python and sci-kit-learn, which accurately forecasted sales outcomes with an accuracy of 85%", 
            "Created visualizations using Tableau to communicate findings to management, leading to implementation of new strategies that improved customer satisfaction."
        ],
        date: "Sep 2014 - Feb 2015",
        location: "Lagos"
    },

]
