export const aboutReducer = (state ={about: []}, action) =>{
    switch(action.type){
        case "SUCCESSFUL":
            return {
                about: action.payload,
            }
        case "FAILED":
            return {
                error: action.payload
            }
        default:
            return state;
    }
}