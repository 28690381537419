import contact from "../ImageComponent/contact_me.jpg"

export const HireMeData = [
    {
        title: "Contact",
        description: "Want to get in touch? Please use the contact information below.",
        email: "mailto: 3hamedsanusi@gmail.com",
        tel: "tel:+19124054239",
        image:contact
    },

]
