import { createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import {reducers} from "../reducers"


const reducer = reducers

const middleware = [thunk]

const initialState = {};

export const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware)
      // other store enhancers if any
    )
  );



