import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {projectData} from "./Project"



const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    marginBottom: 50,
    // marginTop: 2,
    borderRadius: 20,
    border: "3px solid gray",
    ['@media (max-width:550px)']: { // eslint-disable-line no-useless-computed-key
            maxWidth: 700,
          },
  },
  // media: {
    // height: 300,
    // width: 300,
  // },

});

export default function ProjectCard() {
  const classes = useStyles();
  const newData = projectData

  return (
    
  <div style={{margin: "auto"}}>
         {newData.map((datas, index)=>(
             <Card className={classes.root} key={index}> 
             <CardActionArea style={{ backgroundColor: " white", color: "black" }}>
               <Link href={datas.site_link} target= "_blank" >
                 <CardMedia 
                     className="media"
                     src={datas.image}
                     component = "img"
                     title="Contemplative Reptile"
                 /> 
                 </Link>
                 
                 <CardContent >
                    <Typography>
                     <span style={{color:"black",fontWeight:"bold", }}>{datas.title}</span>
                     </Typography>
                     <Typography >
                       <small>{datas.description}</small>
                     </Typography>
                 </CardContent>
                 </CardActionArea>
                 <CardActions>
                 <Link href={datas.site_link} size="small" color="primary" target= "_blank" >
                 <small>Visit Site</small>
                 </Link>
                 <Link href={datas.github_link} size="small" color="primary" target= "_blank">
                     <small>Github Link</small>
                 </Link>
                 </CardActions>
             </Card>
         ))
         }
     </div> 
   
  )
}
