import {AboutData} from "../components/menuComponent/AboutComponent/About"

export const aboutSuccess = () => {
    const dispatch = AboutData;
    return{
        type:"SUCCESSFUL",
        payload: dispatch
    }
}


export const aboutFailure = () => {
    const dispatch = "This data cannot be loaded";
    return{
        type:"FAILED",
        payload: dispatch
    }
}

