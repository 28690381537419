import cert1 from "../ImageComponent/cert1.png"
import cert2 from "../ImageComponent/cert2.png"
import cert3 from "../ImageComponent/cert3.png"
import cert4 from "../ImageComponent/cert4.png"
import cert5 from "../ImageComponent/cert5.png"
import cert6 from "../ImageComponent/cert6.png"
import cert7 from "../ImageComponent/cert7.png"
import cert8 from "../ImageComponent/cert8.png"
import cert9 from "../ImageComponent/cert9.png"
import cert10 from "../ImageComponent/cert10.png"
import cert11 from "../ImageComponent/cert11.png"
import scrum from "../ImageComponent/scrum.png"


export const certificationData = [
    {
        title: "IBM Full Stack Software Developer Assessment",
        description:" VRKAYKQBZZ4S",
        // site_link: "#",
        // github_link: "#",
        image: cert1
    },
    {
        title: "IBM Introduction to Web Development with HTML, CSS, JavaScript",
        description:" N7T3VJNEVYUP",
        // site_link: "#",
        // github_link: "#",
        image: cert2
    },
    {
        title: "Scrum Fundamentals Certified",
        description:"832637",
        // site_link: "#",
        // github_link: "#",
        image: scrum
    },
    {
        title: "LinkedIn Learning - JavaScript Essential Training",
        description:" : AdLuJYzAtkqGBpYcDzAr9USRAmYH",
        // site_link: "#",
        // github_link: "#",
        image: cert6
    },
    {
        title: "LinkedIn Learning - React.js Essential Training",
        description:"AfxDfp8qLDhIeJfigLqeTcoPNBTo",
        // site_link: "#",
        // github_link: "#",
        image: cert11
    },
    {
        title: "LinkedIn Learning - Programming Foundations:Object-Oriented Design",
        description:"AXZF5rjH365aoqyivos-9CC4ScMI",
        // site_link: "#",
        // github_link: "#",
        image: cert10
    },
    {
        title: "LinkedIn Learning - Bootstrap 4 Essential Training",
        description:"AaJnGVUAQ6U3aLnrQAjTdZbzfRPr",
        // site_link: "#",
        // github_link: "#",
        image: cert3
    },
    {
        title: "LinkedIn Learning - HTML Essential Training",
        description:"SqfoIofmKLz4m6zEeE4HbbN2tg2",
        // site_link: "#",
        // github_link: "#",
        image: cert4
    },
    {
        title: "LinkedIn Learning - CSS Essential Training",
        description:"  AZe-rCTecNSun3pWTwnHhdHfO3bA",
        // site_link: "#",
        // github_link: "#",
        image: cert5
    },
    {
        title: "LinkedIn Learning - Learning Python",
        description:"Ad9eG5TDt2UzB-byHZnmab6d-Ndk",
        // site_link: "#",
        // github_link: "#",
        image: cert7
    },
    {
        title: "LinkedIn Learning - Learning Vue.js",
        description:"AcdVc6dSjQOZvkE9yZ044EJ_fD3o",
        // site_link: "#",
        // github_link: "#",
        image: cert8
    },
    {
        title: "LinkedIn Learning - Python Essential Training",
        description:" Af_jSrWbj8CI_Kyu3vklqnqFxdbm",
        // site_link: "#",
        // github_link: "#",
        image: cert9
    },

]


