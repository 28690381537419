import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import Link from '@material-ui/core/Link';

const SocialNetwork = () => {
    return ( 
        <div className="social">
        <Link href="https://github.com/shoptsc" target="_blank"><GitHubIcon/></Link>
        <Link href="https://www.linkedin.com/in/hamed-sanusi/" target="_blank"><LinkedInIcon/></Link>
        <Link href="https://twitter.com/thatblackboy11" target="_blank"><TwitterIcon/></Link>
        <Link href=" https://web.facebook.com/sanusi.hamed1" target="_blank"><FacebookIcon/></Link>
        </div>
     );
}
 
export default SocialNetwork;