import ComputerIcon from '@material-ui/icons/Computer';
import { useState } from "react";
import ReactTypingEffect from 'react-typing-effect';
import {Navbar, Nav} from 'react-bootstrap'
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import Link from '@material-ui/core/Link';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Modal from './ModalComponent';
import AboutCard from "./menuComponent/AboutComponent/AboutCard"
import ProjectCard from './menuComponent/ProjectComponent/ProjectCard';
import ExperienceCard from './menuComponent/ExperienceComponent/ExperienceCard';
import SkillCard from './menuComponent/SkillSetComponent/SkillCard';
import HireMeCard from './menuComponent/HireMeComponent/HireMeCard';
import CertificationCard from './menuComponent/CertificationComponent/CertificationCard';


const HomePage = ({handleClick}) => {
    const borderLight ={
        border:"3px solid white",
        borderRadius: "50%",
        height: "80px",
        width: "80px",
        position: "relative",
        margin: "10px auto",
        cursor: "pointer",
    }
    const borderDark ={
      border:"3px solid black",
      borderRadius: "50%",
        height: "80px",
        width: "80px",
        position: "relative",
        margin: "10px auto",
        cursor: "pointer",
    }
   
    const vLineDark = {
        borderLeft: "3px solid white"
    }
    const vLineLight = {
        borderLeft: "3px solid black"
    }

    const hLineDark = {
        backgroundColor: "white"
    }
    const hLineLight = {
        backgroundColor: "black"
    }

    const menuBorderLight = {
        border: "1px solid black"
    }

    const menuBorderDark = {
        border: "1px solid white"
    }

    const socialDark = {
        color: "white"
    }
    const socialLight = {
        color: "black"
    }

    const TypingDark = {
        color: "goldenrod",
        fontSize: "1.3em",
        fontWeight:"bold"
    }
    const TypingLight = {
        color: "rgb(221, 160, 7)",
        fontSize: "1.3em",
        fontWeight:"bold"
    }

    const [headStyle, setHeadStyle] = useState(false)
    const [vStyle, setVStyle] = useState(true)
    const [hStyle, setHStyle] = useState(true)
    const [menu, setMenu] = useState(true)
    const [social, setSocial] = useState(true)
    const [typing, setTyping] = useState(true)

    const handleHeadClick = () =>{
        setVStyle(!vStyle)
        setHStyle(!vStyle)
        setMenu(!menu)
        setSocial(!social)
        setTyping(!typing)
        setHeadStyle(!headStyle)
        
        handleClick()
    }

    const [show, setShow] = useState(false)
    const [projectShow, setProjectShow] = useState(false)
    const [experienceShow, setExperienceShow] = useState(false)
    const [skillShow, setSkillShow] = useState(false)
    const [hireShow, setHireShow] = useState(false)
    const [certificateShow, setCertificationShow] = useState(false)
    

    return ( 
        <div >

            {/* The Top Line Component */}
            <div onClick={handleHeadClick} style={headStyle? borderLight : borderDark} className="circle" ><ComputerIcon className="circle_content"/>
            <div style={vStyle? vLineLight : vLineDark} className="v_line" ></div>
            </div>
            <hr style={hStyle? hLineLight : hLineDark} className="hr"/>

            {/* The Body Component */}

            <div>

                <ReactTypingEffect
                    style={social? TypingLight : TypingDark}
                    speed={500}
                    eraseSpeed={500}
                    eraseDelay={500}
                    typingDelay={500}
                    cursor="..."
                    text={"I am Sanusi Hamed"}
                />
                    <br/>
                    <br/>
                   <ReactTypingEffect
                        style={social? socialLight : socialDark}
                        speed={150}
                        eraseSpeed={70}
                        eraseDelay={70}
                        typingDelay={70}
                        cursor="..."
                        text={[
                            "Please click the circle to switch to dark mode and vice versa.",
                            "I create websites and web applications",
                            "I don't call it bug – I call it an undocumented feature. ",
                            "The only way of writing less bugs is writing less code.",
                            "Fashion and Gym lover"
                            ]}
                    />
                    <p style={{textTransform:"uppercase", fontWeight:"bold"}}>Before software can be reusable, it first has to be usable. </p>
            </div>

            {/* The bottom lines */}

            <div className="menuList">
            
                <hr 
                style={hStyle? hLineLight : hLineDark} />
                
                <div 
                style={vStyle? vLineLight : vLineDark} 
                className="menu_vline">
                </div>

            </div>

            {/* Menu Content */}
            
            <Navbar className="menuContent" bg="light" expand="lg">
            <Navbar.Collapse id="basic-navbar-nav">
            
                <Nav className="mr-auto">

                     {/* About Component */}
                    <div style={menu? menuBorderLight : menuBorderDark} className="content">

                        <button style={social? socialLight:socialDark} className="btn" onClick ={()=>setShow(true)}>
                        <i className="fa fa-user"></i>&nbsp; About 
                        </button>
                        <Modal title="About" children={<AboutCard/>} onClose={()=>setShow(false)} show={show}   />
                            
                    </div>


                            {/* Project Component */}
                    <div style={menu? menuBorderLight : menuBorderDark} className="content">

                        <button style={social? socialLight:socialDark} className="btn" onClick ={()=>setProjectShow(true)}>
                        <i className="fa fa-laptop"></i>&nbsp; Projects
                        </button>
                        <Modal title="Project" children={<ProjectCard/>} onClose={()=>setProjectShow(false)} show={projectShow}/>
                            
                    </div>

                    
                        {/* Experience Component */}
                    <div style={menu? menuBorderLight : menuBorderDark} className="content">

                        <button style={social? socialLight:socialDark} className="btn" onClick ={()=>setSkillShow(true)}>
                        <i className="fa fa-cogs"></i>&nbsp; Experience 
                        </button>
                        <Modal title="Experience" children={<ExperienceCard/>} onClose={()=>setSkillShow(false)} show={skillShow}   />
                            
                    </div>


                        {/* Skill Sets Component */}
                    <div style={menu? menuBorderLight : menuBorderDark} className="content">

                        <button style={social? socialLight:socialDark} className="btn" onClick ={()=>setExperienceShow(true)}>
                        <i className="fa fa-bar-chart"></i>&nbsp; Skills 
                        </button>
                        <Modal title="Skills" children={<SkillCard/>} onClose={()=>setExperienceShow(false)} show={experienceShow}   />
                            
                    </div>


                        {/* Certification Component */}
                        
                    <div style={menu? menuBorderLight : menuBorderDark} className="content">

                        <button style={social? socialLight:socialDark} className="btn" onClick ={()=>setCertificationShow(true)}>
                        <i className="fa fa-bar-chart"></i>&nbsp; Certifications 
                        </button>
                        <Modal title="Certifications " children={<CertificationCard/>} onClose={()=>setCertificationShow(false)} show={certificateShow}   />
                        
                    </div>


                         {/* Hire Me Component */}
                    <div style={menu? menuBorderLight : menuBorderDark} className="content">

                        <button style={social? socialLight:socialDark} className="btn" onClick ={()=>setHireShow(true)}>
                            <i className="fa fa-phone-square"></i>&nbsp; Hire Me 
                        </button>
                        <Modal title="Hire Me" children={<HireMeCard/>} onClose={()=>setHireShow(false)} show={hireShow} />
    
                    </div>

                    


                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <hr style={hStyle? hLineLight : hLineDark} className="hr-down"/>


        {/* Social Network */}

        <div className="social">

        <Link style={social? socialLight : socialDark} href="https://github.com/shoptsc" target="_blank"><GitHubIcon/>
        </Link>

        <Link style={social? socialLight : socialDark} href="https://www.linkedin.com/in/hamed-sanusi/" target="_blank"><LinkedInIcon/>
        </Link>

        <Link style={social? socialLight : socialDark} href="https://twitter.com/thatblackboy11" target="_blank"><TwitterIcon/>
        </Link>

        <Link style={social? socialLight : socialDark}
         href=" https://web.facebook.com/sanusi.hamed1" target="_blank"><FacebookIcon/>
         </Link>

        </div>

        {/* Footer */}

        <div className="footer">
            <small>&copy;{new Date().getFullYear()} with <FavoriteIcon className="love" style={{fontSize: "small"}}/> from tsc</small>
        </div>

    </div>
     );
}
 
export default HomePage;