import newImage from "../ImageComponent/my_image.jpg"


export const AboutData = [
    {
        profile1: "Hi, my name is Hamed Sanusi and I am a fullstack software developer with a passion for creating efficient and user-friendly applications.",
        
        profile2:"I have experience with a variety of programming languages, including Python, JavaScript, and R. I am skilled in developing web applications for mobile and desktop platforms ",
        
        profile3: "I am a proactive learner and I am constantly seeking opportunities to expand my skills and knowledge. I am a team player and enjoy working with others to find the best solutions to challenges. I am excited to bring my skills and expertise to a new company and contribute to the development of cutting-edge software.",
        
        profile4: "Want to know more? Here's where you can find me!.",

        image : newImage
    
    }

]

