import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import {AboutData} from "./About";
import {useSelector, useDispatch} from "react-redux" ;
import {aboutSuccess} from "../../../actions/aboutAction"

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    marginBottom: 50,
    marginTop: 20,
    borderRadius: 20,
    border: "3px solid gray",
    ['@media (max-width:550px)']: { // eslint-disable-line no-useless-computed-key
            maxWidth: 700,
          },
  },
  // media: {
  //   height: 300,
  //   width: 300,
  // },

});

export default function AboutCard() {
  const classes = useStyles();
  // const newData = AboutData

  const dispatch = useDispatch();

  const aboutNewData = useSelector(state=>state.about)
  const {about} = aboutNewData
  
  const newData = about
 
  useEffect(()=>{
    dispatch(aboutSuccess())

}, [dispatch])

  return (
    <div style={{margin: "auto"}}>
        {newData.map((datas, index)=>(
            <Card className={classes.root} key={index}>
                <CardActionArea style={{backgroundColor:" white", color:"black"}}>
                <CardMedia style={{paddingTop:"50px"}}
                    className="media"
                    image={datas.image}
                   
                    title="Contemplative Reptile"
                />
                <CardContent >
                    <Typography style={{textAlign:"left", marginBottom:"10px", fontSize:"1em", paddingLeft:"12px"}}>
                     {datas.profile1}
                    </Typography>
                    <Typography style={{textAlign:"left", marginBottom:"10px", fontSize:"1em", paddingLeft:"12px"}}>
                     {datas.profile2}
                    </Typography>
                    <Typography style={{textAlign:"left", marginBottom:"10px", fontSize:"1em", paddingLeft:"12px"}}>
                     {datas.profile3}
                    </Typography>
                    <Typography style={{textAlign:"left", marginBottom:"10px", fontSize:"1em", paddingLeft:"12px"}}>
                     {datas.profile4}
                    </Typography>
                </CardContent>
                </CardActionArea>
            </Card>
        ))
        }
    </div>
   
  );
}