import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {HireMeData} from "./HireMe"
import Link from '@material-ui/core/Link';


const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    marginBottom: 50,
    marginTop: 20,
    borderRadius: 20,
    border: "3px solid gray",
    ['@media (max-width:550px)']: { // eslint-disable-line no-useless-computed-key
            maxWidth: 700,
          },
  },


});

export default function HireMeCard() {
  const classes = useStyles();
  const newData = HireMeData

  return (
    <div style={{margin: "auto"}}>
        {newData.map((datas, index)=>(
            <Card className={classes.root} key={index}>
                <CardActionArea style={{backgroundColor:" white", color:"black"}}>
               
                <CardContent >
                    <Typography >
                      {datas.description}
                    </Typography>

                    <Link href={datas.tel} target= "_blank">
                <CardMedia 
                     className="media"
                     src={datas.image}
                     component = "img"
                     title={datas.title}
                 /> 
                 </Link>
                <div style = {{display: "flex", margin: "0 50px"}}>
                  <Link href="https://wa.me/19124054239" target= "_blank" style={{padding: "0 20px"}}>
                      <p>CHAT ME</p>
                    </Link>
                  
                    <Link  href={datas.email}     target= "_blank" style={{padding: "0 20px"}}>
                      <p>EMAIL ME</p>
                    </Link>
                </div>
                </CardContent>
                </CardActionArea>
                
            </Card>
        ))
        }
    </div>
   
  );
}