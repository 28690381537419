import fashion from "../ImageComponent/fashion.png"
import vueLoader from "../ImageComponent/vueloader.png"
import carrotOil from "../ImageComponent/carrotoil.png"
import musicPlayer from "../ImageComponent/musicplayer.png"
import signUp from "../ImageComponent/signup.png"
import weatherApp from "../ImageComponent/weatherApp.png"
import todoList from "../ImageComponent/todoList.png"
import newsLetter from "../ImageComponent/newsLetter.png"
import listingApp from "../ImageComponent/listingApp.jpg"
import recipeApp from "../ImageComponent/recipeApp.png"
import leaveRequestApp from "../ImageComponent/leaveRequestApp.png"
import simpleIC from "../ImageComponent/simpleIC.png"
import clinic from "../ImageComponent/clinic.png"


export const projectData = [
    {
        title: "Clinic Plus Web-Based Application",
        description:"Created a web-based application for a small clinic to manage patient appointments and electronic medical records. Implemented secure authentication and authorization for clinic staff and patients.Created a calendar view for scheduling appointments and a patient portal for online appointment booking.",
        site_link: "https://clinicplusapp.org/",
        github_link: "https://github.com/shoptsc/clinicApp_frontend",
        image: clinic
    },
    {
        title: "Real Estate Property Listing For Acegene",
        description:" Designed and developed a web application for a real estate agency to showcase their properties for sale and rent. Developed a system for agents to input property information, upload photos, and set up showings. Created an interactive map feature to display the location of each property.Utilized a cloud-based database to store and retrieve property data.",
        site_link: "https://www.acegenelisting.com/",
        github_link: "https://github.com/shoptsc/listing_property_frontend",
        image: listingApp
    },
    {
        title: "Leave Request Web-Based Application",
        description:"Developed a web application for a company to manage employee leave requests. Created a simple and intuitive interface for employees to submit leave requests, view their leave balance, and see the status of their requests.",
        site_link: "https://scelloo-leaveapp.netlify.app/",
        github_link: "https://github.com/shoptsc/scelloo_leaveapp_frontend",
        image: leaveRequestApp
    },
    {
        title: "Restaurant Recipe Application",
        description:"Successfully built a website that displays different type of recipe for some country. Made request to the free API using fetch request and storing it in a state. Website was built on React JS, Styled-Components, Framer Motion, React-Slide and React-Icon",
        site_link: "https://recipe-program-app.netlify.app/",
        github_link: "https://github.com/shoptsc/recipe-app",
        image: recipeApp
    },
    
    {
        title: "A Fashion Website For A Fashion Company",
        description:" Successfully built a website that display the relevant information about the business. Website was built using HTML, CSS and JavaScript and deployed on Netlify",
        site_link: "https://shoptsc.netlify.app/",
        github_link: "https://github.com/shoptsc/shoptsc.github.io",
        image: fashion
    
    },
    {
        title: "Simple Interest Calculator",
        description:"Successfully built a Web Application that calculates the simple interest from a given amount for a given period of time. The Application was built using HTML, CSS and Vanilla JavaScript and deployed on Netlify",
        site_link: "https://simpleic.netlify.app/",
        github_link: "https://github.com/shoptsc/simple-interest-calculator.git",
        image: simpleIC
    },
    {
        title: "Weather Application",
        description:"A Weather Application that changes with respect to the country entered in the search box. It is written using VueJS, Vue-Bootstrap and data collected from the weather API using Axios.",
        site_link: "https://ourweatherapp.netlify.app/",
        github_link: "https://github.com/shoptsc/weatherApp",
        image: weatherApp
    },
    {
        title: "NewsLetter",
        description:"A NewsLetter Application Built using NodeJs, EJS Template Engine, Mailchimp and deployed using Heroku",
        site_link: "https://sleepy-cove-81610.herokuapp.com/",
        github_link: "#",
        image: newsLetter
    },
    {
        title: "Loading Spinner",
        description:"A Simple TimeOut Loader Built using Vuejs and Vue-Bootstrap. You can change the message and also adjust the timeout from the default.",
        site_link: "https://vueloder.netlify.app/",
        github_link: "https://github.com/shoptsc/vueloading",
        image: vueLoader
    },
    {
        title: "Carrot Oil Website",
        description:"Skin Carrot Organic Oil Website designed using Bootstrap.",
        site_link: "https://carrotoil.netlify.app/",
        github_link: "https://github.com/shoptsc/hms_organic_oil",
        image: carrotOil
    },
    {
        title: "Music Player",
        description:"A Simple Music Player written in Javascript, HTML and CSS .",
        site_link: "https://musicp.netlify.app/",
        github_link: "https://github.com/shoptsc/Music_Player",
        image: musicPlayer
    },
    {
        title: "Sign Up Validation",
        description:"A Simple Sign Up page built using HTML and CSS and Validation using Javascript.",
        site_link: "https://signupvalidate.netlify.app/",
        github_link: "https://github.com/shoptsc/signup",
        image: signUp
    },
    {
        title: "A ToDo List",
        description:"A Simple Todo List built using HTML and CSS and Javascript.",
        site_link: "https://shoptsc.github.io/mytodo/",
        github_link: "https://github.com/shoptsc/mytodo",
        image: todoList
    },


]


