import './App.css';
import Home from './components/Home';
import { useState } from 'react';
import ScreenFlash from './ScreenFlash';
// import {Browserdiv as div, Route, } from "react-div-dom"

function App() {


  const darkColors =  {
    backgroundColor: "black",
    color: "white"
  }
  const lightColors =  {
      backgroundColor: "white",
      color: "black"
  }

  const [color, setColor] = useState(true);
  const [change, setChange] = useState(true);

  const changeColor = () =>{
    setColor(!color)
  }


  setTimeout(()=>setChange(true), 6000)
    
      
    switch(change){
      case false:
        return  <ScreenFlash className="flash"/>
        
      case true:
        return( <div  style={color? lightColors : darkColors } 
                  className="App"> 
                    <Home changeColor={changeColor} /> 
                </div>)

        default:
        break;
    }
             
                  
}

export default App;
