import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {experienceData} from "./Experience"


// import ListItem from '@mui/material/ListItem';
// or
// import { ListItem } from '@mui/material';
// import ListItemText from '@mui/material/ListItemText';
// // or
// import { ListItemText } from '@mui/material';


const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    marginBottom: 50,
    marginTop: 20,
    borderRadius: 20,
    border: "3px solid gray",
    ['@media (max-width:550px)']: { // eslint-disable-line no-useless-computed-key
            maxWidth: 700,
          },
  },
//   media: {
//     // height: 300,
//     // width: 300,
//   },

});

export default function ExperienceCard() {
  const classes = useStyles();
  const newData = experienceData

  return (
    <div style={{margin: "auto",}}>
        {newData.map((datas, index)=>(
            <Card className={classes.root} key={index}>
                <CardActionArea style={{backgroundColor:" white", color:"black",}}>
    
                <CardContent style={{}}>
                    <Typography variant="body1" style={{padding:"10px 0 0"}}>
                        <span style={{textTransform:"uppercase", fontWeight:"bolder"}}>{datas.company}</span>
                    </Typography>
                    <Typography variant="body2">
                       <span style={{textTransform:"capitalize", fontWeight:"bolder"}}> {datas.role}</span>
                    </Typography>
                    <Typography variant="overline">
                       <span style={{textTransform:"capitalize", fontWeight:"bolder"}}> {datas.date}</span>
                    </Typography>

                    <ul style={{textAlign: 'left'}}>
                      {datas.duties.map((duty, index) => (
                        <li key={index} style={{padding: '3px 0'}}>{duty}</li>
                      ))}
                    </ul>
             {/* <ListItem key = {index} style={{textAlign:"justify"}}> 
                              <ListItemText primary={duty}/> 
                            </ListItem>)) */}
                  
                </CardContent>
                </CardActionArea>
                
            </Card>
        ))
        }
    </div>
   
  );
}