import { useState } from "react";

import HomePage from "./HomePage";




const Home = ({changeColor}) => {
  
    const darkColors =  {
        backgroundColor: "black",
        color: "white"
    }
    const lightColors =  {
        backgroundColor: "white",
        color: "black"
    }
   
    const [style, setStyle] = useState(true)
   
    const handleClick = () =>{
        setStyle(!style)
        changeColor()
    }


    return ( 
        <div 
        style={style? lightColors : darkColors} 
        className="home">
            <div className="sub-home" style={style? lightColors : darkColors }>
                <HomePage handleClick={handleClick} />
            </div>
        </div>
     );
}
 
export default Home;