import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {skillData} from "./Skills"
import "./skill.css";


const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    marginBottom: 50,
    marginTop: 20,
    borderRadius: 20,
    border: "3px solid gray",
    ['@media (max-width:550px)']: { // eslint-disable-line no-useless-computed-key
            maxWidth: 700,
          },
  },
});

export default function SkillCard() {
  const classes = useStyles();
  const newData = skillData
 

  
  return (
    <div style={{margin: "auto"}}>

        {newData.map((datas, index)=>(
            <Card className={classes.root} key={index}>
              
                <CardActionArea className="card-home" style={{backgroundColor: "white", color: "black"}}>

                <CardContent >
                  <Typography>
                  <span style={{backgroundColor:"white", color:"black", padding:"5px", borderRadius:"10px", textTransform:"uppercase", fontWeight:"bold"}}>{datas.title} tools</span>
                  </Typography>
                </CardContent>

                <div className="cardImg">
                    <div className="mediaCard">
                    {
                      (datas.image).map((img, index)=>
                        <div key={index}>
                        <CardMedia className="newMedia"
                          src={img.pic}
                          component = "img"
                          title={img.name}
                        />
                        <small>{img.name}</small>
                        </div>
                      )
                    }
                    </div>
                </div>
                
              </CardActionArea>
          
            </Card>
        ))
        }
    </div>
   
  );
}